import React, { ReactElement, useRef } from "react";
import Tooltip from "@material-ui/core/Tooltip";

export const withCopyTooltip = (WrappedComponent: any, copyString: string) => {
  return function CopyTooltip(): ReactElement {
    const [openTooltip, setOpenTooltip] = React.useState(false);
    const elRef = useRef<HTMLHeadingElement>(null);
    const isOS = () => {
      return navigator.userAgent.match(/ipad|iphone/i);
    };
    const isMob = () => {
      return navigator.userAgent.match(/Android|iphone/i);
    };
    const onCopyIban = () => {
      const textField = document.createElement("textarea");
      textField.innerText = copyString;
      if (isMob()) {
        const textareaContainer = document.getElementsByClassName(
          "textareaContainerMob"
        );

        textareaContainer && textareaContainer[0].appendChild(textField);
      } else {
        const textareaContainer = document.getElementById("textareaContainer");
        textareaContainer && textareaContainer.appendChild(textField);
      }
      if (isOS()) {
        textField.contentEditable = "true";
        textField.readOnly = true;
        const range = document.createRange();
        range.selectNodeContents(textField);
        const selection = window.getSelection();
        selection?.removeAllRanges();
        selection?.addRange(range);
        textField.setSelectionRange(0, 999999);
      } else {
        textField.select();
        textField.focus();
      }
      document.execCommand("copy");
      const element = elRef.current;
      if (element) {
        element.focus();
      }
      textField.remove();
      setOpenTooltip(true);
      setTimeout(() => {
        setOpenTooltip(false);
      }, 1000);
    };
    return (
      <Tooltip
        PopperProps={{
          disablePortal: true,
        }}
        onClose={() => setOpenTooltip(false)}
        open={openTooltip}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title="kopiert"
      >
        <div
          onClick={(event) => {
            event.stopPropagation();
            onCopyIban();
          }}
          ref={elRef}
        >
          <WrappedComponent />
        </div>
      </Tooltip>
    );
  };
};
